<template>
  <div class="mainbookprint">

  </div>  
</template>

<script>
// import DocumentService from '@/services/DocumentService'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import ledgerEN from './mainbookDescEn'
import ledgerHR from './mainbookDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import {sortByDate, dynamicSort, parseNum, round} from '@/snippets/allSnippets'
import dayjs from 'dayjs'

export default {
  name: 'mainbookcard3bytypesprint',
  props: ['kpi'],
  data () {
    return {
      lang: {},
      langC: {},
      comp: {},
      docDefinition: {},
      content: [],
      items: [],
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      locCreatedAt: '',
      locUpdatedAt: '',
      order: 0,
      RecCash: 0,
      RecBank: 0,
      RecFree: 0,
      RecVAT: 0,
      RecSpecial: 0,
      RecTotal: 0,
      ExpCash: 0,
      ExpBank: 0,
      ExpFree: 0,
      ExpVAT: 0,
      ExpSpecial: 0,
      ExpTotal: 0,
      tRecCash: 0,
      tRecBank: 0,
      tRecFree: 0,
      tRecVAT: 0,
      tRecSpecial: 0,
      tRecTotal: 0,
      tExpCash: 0,
      tExpBank: 0,
      tExpFree: 0,
      tExpVAT: 0,
      tExpSpecial: 0,
      tExpTotal: 0,
      totalDoc: 0,
      ana: {}
    }
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = ledgerEN
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = ledgerHR
      this.langC = commHr
    }
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    
  },
  methods: {
    print () {
      this.ana = this.$store.state.printObj ? this.$store.state.printObj : {}
      this.totalDoc = 0

      this.initDocument()
      this.addHeaderNoLogo()
      this.addEmptyRow()
      this.addLine()
      this.addItemsHeader()
      this.addLine()
      this.addItems()
      this.addLine()
      this.addItemsFoter()
      this.addLine()
      this.addTotalsFoter()

      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {
        footer: function(currentPage, pageCount) {
           return ({text: ' Stranica: ' + currentPage.toString() + ' od ' + pageCount, fontSize: 8, alignment: 'center' });
         },
      }
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'landscape'
      this.docDefinition.pageMargins = [ 20, 20, 20, 20 ]
      this.content = []
    },

    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 800,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addHeaderNoLogo () {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '35%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              },
              {
                // % width
                width: '45%',
                text: [
                  { text:  ( 'Knjiga primitaka/izdataka - po vrstama\n'), fontSize: 18, bold: true },
                ]   
              },
              {
                // % width
                width: '20%',
                text: [                 
                  { text: ' Datum ispisa: ' + dayjs().format('DD.MM.YYYY, h:mm:ss a'), fontSize:8}
                ]   
              }

            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addItemsHeader () {
        const docPart = {
        columns: [
              {
                width: '3%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.id
              },
              {
                width: '20%',
                fontSize: 8,
                alignment: 'left',
                text: 'Vrsta'
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'left',
                text: 'Pr.\n'+this.lang.RecCash
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'right',
                text: 'Pr.\n' + this.lang.RecBank
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'right',
                text: 'Pr.\n' + this.lang.RecFree
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'right',
                text:'Pr.\n' +  this.lang.RecVAT
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'right',
                text: 'Pr.\n' + this.lang.RecSpecial
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: 'Pr.\n' + this.lang.RecTotal
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'right',
                text: 'Iz.\n' + this.lang.ExpCash
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'right',
                text: 'Iz.\n' + this.lang.ExpBank
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'right',
                text: 'Iz.\n' + this.lang.ExpFree
              },
              {
                width: '4%',
                fontSize: 8,
                alignment: 'right',
                text: 'Iz.\n' + this.lang.ExpVAT
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'right',
                text: 'Iz.\n' + this.lang.ExpSpecial
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: 'Iz.\n' + this.lang.ExpTotal
              }
            ]
          }
        this.content.push(docPart) 
    },
    addItems () {
            this.order = 0           

            if (this.ana.items) {
              this.ana.items.map(item => {
                  
                  this.order += 1
                  const docPart = {
                  columns: [
                        {
                          width: '3%',
                          fontSize: 8,
                          alignment: 'left',
                          text: parseInt(this.order)
                        },
                        {
                          width: '20%',
                          fontSize: 8,
                          alignment: 'left',
                          text: item.BookTypeName
                        },
                        {
                          width: '6%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.RecCash)
                        },
                        {
                          width: '6%',
                          fontSize: 8,
                          alignment: 'right',
                          text:this.formatPrice(item.RecBank)
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.RecFree)
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.RecVAT)
                        },
                        {
                          width: '6%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.RecSpecial)
                        },
                        {
                          width: '7%',
                          fontSize: 8,
                          alignment: 'right',
                          bold: true,
                          text: this.formatPrice(item.RecTotal)
                        },
                        {
                          width: '6%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.ExpCash)
                        },
                        {
                          width: '6%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.ExpBank)
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.ExpFree)
                        },
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.ExpVAT)
                        },
                        {
                          width: '6%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.ExpSpecial)
                        },
                        {
                          width: '8%',
                          fontSize: 8,
                          alignment: 'right',
                          text:  this.formatPrice(item.ExpTotal)
                        }
                      ]
                    }
                  this.content.push(docPart)
                  }
                
              )
          }
    },

    addItemsFoter () {
        const docPart = {
        columns: [
              {
                width: '3%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '20%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ana.Total1)
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ana.Total2)
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ana.Total3)
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'right',
                bold: true,
                text: this.formatPrice(this.ana.Total4)
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ana.Total5)
              },
              {
                width: '7%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ana.Total6)
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ana.Total7)
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ana.Total8)
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ana.Total9)
              },
              {
                width: '5%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ana.Total10)
              },
              {
                width: '6%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ana.Total11)
              },
              {
                width: '8%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.ana.Total12)
              }
            ]
          }
        this.content.push(docPart)        
    },

    addTotalsFoter () {
        const docPart = {
        columns: [
              {
                width: '7%',
                fontSize: 8,
                alignment: 'right',
                text: 'Saldo:' 
              },
              {
                width: '12%',
                fontSize: 8,
                alignment: 'right',
                text: 'Gotovina\n' +this.formatPrice(this.ana.Uk1)
              },
              {
                width: '12%',
                fontSize: 8,
                alignment: 'right',
                text: 'Žiro račun.\n' +this.formatPrice(this.ana.Uk2)
              },
              {
                width: '12%',
                fontSize: 8,
                alignment: 'right',
                text: 'U naravi.\n' +this.formatPrice(this.ana.Uk3)
              },
              {
                width: '12%',
                fontSize: 8,
                alignment: 'right',
                bold: true,
                text: 'PDV.\n' +this.formatPrice(this.ana.Uk4)
              },
              {
                width: '12%',
                fontSize: 8,
                alignment: 'right',
                text: 'Ostalo.\n' +this.formatPrice(this.ana.Uk5)
              },
              {
                width: '12%',
                fontSize: 8,
                alignment: 'right',
                text: 'Ukupno.\n' +this.formatPrice(this.ana.Uk6)
              },

            ]
          }
        this.content.push(docPart)        
    },
  },
  computed: {
  },
  watch: {
  },
  components: {
  }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>