import { render, staticRenderFns } from "./MainBookCard3ByTypesPrint.vue?vue&type=template&id=5e252c39&scoped=true"
import script from "./MainBookCard3ByTypesPrint.vue?vue&type=script&lang=js"
export * from "./MainBookCard3ByTypesPrint.vue?vue&type=script&lang=js"
import style0 from "./MainBookCard3ByTypesPrint.vue?vue&type=style&index=0&id=5e252c39&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e252c39",
  null
  
)

export default component.exports