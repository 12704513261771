<template>
  <v-container fluid>
    <v-layout row>
      <v-flex xs12 sm12 m12 lg12 ex12>

        <div id="printArea">

                <v-layout row style="border-bottom:solid 1px;">
                    <v-flex xs5>
                        <h4><b><i>{{comp.CompanyName}}</i></b></h4>
                        {{comp.CompanyStreet}} {{comp.CompanyBuildingNameNumber}}<br />
                        {{comp.CompanyPostcode}} {{comp.CompanyCity}}<br />
                         OIB: {{comp.CompanyVATID}}
                    </v-flex>
                    <v-flex xs3>
                      <h2>Kpi - po vrstama</h2>
                  </v-flex>
                    <v-flex xs 4>
                      <p class="text-md-left">
                          Datum izvještaja: {{PrintDate}}<br />
                          Telefon: {{comp.CompanyPhones}}<br />
                          email: {{comp.Companyemail}}<br />
                          <v-btn @click="printAna" class="mr-2">Printaj</v-btn>
                      </p>
                    </v-flex>
              </v-layout>


            <v-layout row style="border-bottom:solid 1px; border-top:solid 1px;">
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                 Pr.Gotovina
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  Pr.Žiro račun
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  Pr.U naravi
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  Pr.PDV
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  Pr.Ostalo 
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  Pr.Ukupno 
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  Iz.Gotovina
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  Iz.Žiro račun
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  Iz.U naravi
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  Iz.PDV
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  Iz.Ostalo 
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  Iz.Ukupno 
                </v-col>
            </v-layout>

            <v-layout row style="border-bottom:solid 1px;">
  
            </v-layout>

            <div v-for="item in finalItems" :key="item.itLine" dense>
              <v-row style="border-top:solid 1px;">
                  <v-col cols="12" sm="5" md="5" xs="5" lg="5" xl="5">
                    <h4><b><i>Vrsta: {{ item.BookTypeName }} </i></b></h4>
                  </v-col>
              </v-row>
              <v-row style="border-bottom:solid 1px;">
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                    {{formatPrice(item.RecCash)}}
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                    {{formatPrice(item.RecBank)}}
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                    {{formatPrice(item.RecFree)}}
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                    {{formatPrice(item.RecVAT)}}
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                    {{formatPrice(item.RecSpecial)}}
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                    {{formatPrice(item.RecTotal)}}
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                    {{formatPrice(item.ExpCash)}}
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                    {{formatPrice(item.ExpBank)}}
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                    {{formatPrice(item.ExpFree)}}
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                    {{formatPrice(item.ExpVAT)}}
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                    {{formatPrice(item.ExpSpecial)}}
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                    {{formatPrice(item.ExpTotal)}}
                  </v-col>
              </v-row>
            </div>
            <v-row style="border-top:solid 1px;">
                  <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                    <h4><b><i>Ukupno</i></b></h4>
                  </v-col>
              </v-row>
            <v-layout row style="border-bottom:solid 1px;">
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  {{formatPrice(Total1)}}
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  {{formatPrice(Total2)}}
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  {{formatPrice(Total3)}}
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  {{formatPrice(Total4)}}
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  {{formatPrice(Total5)}}
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  {{formatPrice(Total6)}}
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  {{formatPrice(Total7)}}
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  {{formatPrice(Total8)}}
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  {{formatPrice(Total9)}}
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  {{formatPrice(Total10)}}
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  {{formatPrice(Total11)}}
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  {{formatPrice(Total12)}}
                </v-col>
            </v-layout>
            <v-row style="border-top:solid 1px;">
                  <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                    <h4><b><i>Saldo</i></b></h4>
                  </v-col>
              </v-row>
            <v-layout row style="border-bottom:solid 1px;">
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  {{formatPrice(Uk1)}}
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  {{formatPrice(Uk2)}}
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  {{formatPrice(Uk3)}}
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  {{formatPrice(Uk4)}}
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  {{formatPrice(Uk5)}}
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  {{formatPrice(Uk6)}}
                </v-col>
            </v-layout>
        </div>
      </v-flex>
    </v-layout>
    <MainBookCard3ByTypesPrint ref="prnt" />
  </v-container>
</template>

<script>
import ledgerEN from './mainbookDescEn'
import ledgerHR from './mainbookDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import {dateFormat, parseNum} from '@/snippets/allSnippets'
import dayjs from 'dayjs'
import MainBookCard3ByTypesPrint from './MainBookCard3ByTypesPrint'
//import PartnerService from '@/services/PartnerService'

export default {
  name: 'mainledgercard3bytypes',
  props: ['items', 'types'],
  data () {
    return {
      dateFormatted: null,
      dateFormatted2: null,
      date1: null,
      date2: null,
      msg: '',
      comp: {},
      havePath: '',
      RecCash: 0,
      RecBank: 0,
      RecFree: 0,
      RecVAT: 0,
      RecSpecial: 0,
      RecTotal: 0,
      ExpCash: 0,
      ExpBank: 0,
      ExpFree: 0,
      ExpVAT: 0,
      ExpSpecial: 0,
      ExpTotal: 0,
      Total1: 0,
      Total2: 0,
      Total3: 0,
      Total4: 0,
      Total5: 0,
      Total6: 0,
      Total7: 0,
      Total8: 0,
      Total9: 0,
      Total10: 0,
      Total11: 0,
      Total12: 0,
      Uk1: 0,
      Uk2: 0,
      Uk3: 0,
      Uk4: 0,
      Uk5: 0,
      Uk6: 0,
      PrintDate: null,
      lang: {},
      langC: {},
      saving: false,
      mainQuery2: {},
      partner: {},
      partners: [],
      itLine : 0,
      finalItems: []
    }
  },
  components: {
    MainBookCard3ByTypesPrint
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }
    this.PrintDate = dayjs(new Date()).format('llll')
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    
    this.Total1 = 0
    this.Total2 = 0
    this.Total3 = 0
    this.Total4 = 0
    this.Total5 = 0
    this.Total6 = 0
    this.Total7 = 0
    this.Total8 = 0
    this.Total9 = 0
    this.Total10 = 0
    this.Total11 = 0
    this.Total12 = 0

    if (this.types && this.types.length > 0 && this.items && this.items.length > 0) {
      this.types.map(typ => {

        this.RecCash = 0
        this.RecBank = 0
        this.RecFree = 0
        this.RecVAT = 0
        this.RecSpecial = 0
        this.RecTotal = 0

        this.ExpCash = 0
        this.ExpBank = 0
        this.ExpFree = 0
        this.ExpVAT = 0
        this.ExpSpecial = 0
        this.ExpTotal = 0

        this.items.map(item => {
          if (item.BookTypeId === typ.id) {

            this.RecCash += parseNum(item.RecCash)
            this.RecBank += parseNum(item.RecBank) 
            this.RecFree += parseNum(item.RecFree)
            this.RecVAT += parseNum(item.RecVAT)
            this.RecSpecial += parseNum(item.RecSpecial)
            this.RecTotal += parseNum(item.RecTotal)

            this.ExpCash += parseNum(item.ExpCash)
            this.ExpBank += parseNum(item.ExpBank)
            this.ExpFree += parseNum(item.ExpFree)
            this.ExpVAT += parseNum(item.ExpVAT)
            this.ExpSpecial += parseNum(item.ExpSpecial)
            this.ExpTotal += parseNum(item.ExpTotal)

            this.Total1 += parseNum(item.RecCash)
            this.Total2 += parseNum(item.RecBank) 
            this.Total3 += parseNum(item.RecFree)
            this.Total4 += parseNum(item.RecVAT)
            this.Total5 += parseNum(item.RecSpecial)
            this.Total6 += parseNum(item.RecTotal)

            this.Total7 += parseNum(item.ExpCash)
            this.Total8 += parseNum(item.ExpBank)
            this.Total9 += parseNum(item.ExpFree)
            this.Total10 += parseNum(item.ExpVAT)
            this.Total11 += parseNum(item.ExpSpecial)
            this.Total12 += parseNum(item.ExpTotal)
          }
        })
        const haveIn = Math.abs(this.RecCash) + Math.abs(this.RecBank) + Math.abs(this.RecFree) + Math.abs(this.RecVAT) + Math.abs(this.RecSpecial) + Math.abs(this.RecTotal)
        const haveOut = Math.abs(this.ExpCash) + Math.abs(this.ExpBank) + Math.abs(this.ExpFree) + Math.abs(this.ExpVAT) + Math.abs(this.ExpSpecial) + Math.abs(this.ExpTotal)
        if (haveIn + haveOut > 0) {
          this.itLine += 1
          const obj= {}
          obj.itLine = this.itLine
          obj.typeid = typ.id
          obj.BookTypeName = typ.BookTypeName
          obj.RecCash = this.RecCash
          obj.RecBank = this.RecBank
          obj.RecFree = this.RecFree
          obj.RecVAT = this.RecVAT
          obj.RecSpecial = this.RecSpecial
          obj.RecTotal = this.RecTotal

          obj.ExpCash = this.ExpCash
          obj.ExpBank = this.ExpBank
          obj.ExpFree = this.ExpFree
          obj.ExpVAT = this.ExpVAT
          obj.ExpSpecial = this.ExpSpecial
          obj.ExpTotal = this.ExpTotal
          this.finalItems.push(obj)
        }
      })
    }
    this.Uk1 = this.Total1 - this.Total7
    this.Uk2 = this.Total2 - this.Total8
    this.Uk3 = this.Total3 - this.Total9
    this.Uk4 = this.Total4 - this.Total10
    this.Uk5 = this.Total5 - this.Total11
    this.Uk6 = this.Total6 - this.Total12
  },
  computed: {

  },
  methods: {
    async printAna() {
      const newObj = {}
      this.ana = {...newObj}
      this.ana.Total1 = this.Total1
      this.ana.Total2 = this.Total2
      this.ana.Total3 = this.Total3
      this.ana.Total4 = this.Total4
      this.ana.Total5 = this.Total5
      this.ana.Total6 = this.Total6

      this.ana.Total7 = this.Total7
      this.ana.Total8 = this.Total8
      this.ana.Total9 = this.Total9
      this.ana.Total10 = this.Total10
      this.ana.Total11 = this.Total11
      this.ana.Total12 = this.Total12

      this.ana.Uk1 = this.Uk1
      this.ana.Uk2 = this.Uk2
      this.ana.Uk3 = this.Uk3
      this.ana.Uk4 = this.Uk4
      this.ana.Uk5 = this.Uk5
      this.ana.Uk6 = this.Uk6
      this.ana.items = this.finalItems ? this.finalItems : []
      await this.$store.dispatch('setPrintObj', this.ana)
      await this.$refs.prnt.print()
    },
    isNumeric(num){
      return !isNaN(num)
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    localDate(dateToFormat) {
       return dateFormat(dateToFormat)
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    print () {
      // opens the "print dialog" of your browser to print the element
      // var bdhtml = window.document.body.innerHTML
      // window.document.body.innerHTML = document.getElementById('printArea').innerHTML
      // window.print()
      // window.document.body.innerHTML = bdhtml
      this.$htmlToPaper('printArea')
    },

    close () {
      this.show = false
    }
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
