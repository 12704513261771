<template>
  <v-container fluid grid-list-md>

          <v-row>
            <v-col xs="4" >
              <h1>{{this.$store.state.documentActivLoc}}</h1>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col xs="4" >
              <span style="font-weight:bold">{{mainbookAna.CompanyNameDatabase}}</span>
            </v-col>
            <v-col xs="1" >
                <span style="font-weight:bold">{{mainbookAna.BusinessYear}}</span>
            </v-col>
            <v-col cols="12" sm="12" md="6" xs="12" lg="6" xl="6" class="pr-3" >
                <v-btn
                  text color="primary"
                  @click="showStart(1)">
                  {{lang.showStartSupp}}
                </v-btn>
                <v-btn
                  text color="primary"
                  @click="showStart(2)">
                  {{lang.showStartBuyers}}
                </v-btn>
                <v-btn
                  text color="primary"
                  @click="showBook">
                  {{lang.showBook}}
                </v-btn>
                <v-btn
                  text color="primary"
                  @click="showView">
                  {{lang.showView}}
                </v-btn>
                <v-btn
                  text color="primary"
                  @click="showBank">
                  {{lang.showBank}}
                </v-btn>
                <v-btn text color="primary"
                  @click="mainbooktypes()">
                  {{lang.MainBookTypes1}}
                </v-btn>
                <v-btn text color="primary"
                  @click="mainbookdocs()">
                  {{lang.MainBookDocs1}}
                </v-btn>
              </v-col>
          </v-row>
           <v-row no-gutters>
            <v-col xs="4" >

            </v-col>
            <v-col xs="2" >

            </v-col>
            <v-col cols="12" sm="6" md="4" xs="4" lg="4" xl="4" class="pr-3" v-if="dispBook">
              <v-spacer></v-spacer>
              <v-btn
                class="green mr-2"
                @click="goto(1)">
                {{lang.AddReceive}}
              </v-btn>
              <v-btn
                class="primary"
                @click="goto(2)">
                {{lang.AddExpense}}
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="mt-1 mb-3"  v-if="dispView"></v-divider>

          <br>

          <v-row v-if="dispView">
              <v-col cols="12" sm="12" md="2" xs="2" lg="2" xl="2" class="pr-3">
                <v-dialog
                  ref="dialog1"
                  persistent
                  v-model="modal1"
                  width="290px"
                  :return-value.sync="startDate"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="formatDate1"
                      :label="langC.FromDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"

                    ></v-text-field>
                  </template>

                  <v-date-picker v-model="startDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="modal1 = false">Cancel</v-btn>
                    <v-btn color="primary" @click="$refs.dialog1.save(startDate)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" sm="12" md="2" xs="2" lg="2" xl="2" class="pr-3">
                <v-dialog
                  ref="dialog2"
                  persistent
                  v-model="modal2"
                  width="290px"
                  :return-value.sync="endDate"
                >
                 <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="formatDate2"
                      :label="langC.ToDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>

                  <v-date-picker v-model="endDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="modal2 = false">Cancel</v-btn>
                    <v-btn color="primary" @click="$refs.dialog2.save(endDate)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>


              <v-col cols="12" sm="12" md="2" xs="2" lg="2" xl="2" class="pr-3">
                <v-autocomplete
                    :items="partners"
                    v-model="selectPa"
                    :label="langC.SelectPartner"
                    item-text="partnerName"
                    item-value="id"
                    return-object
                    persistent-hint
                    clearable
                  ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6" md="2" xs="2" lg="2" xl="2" class="pr-3">
                <v-autocomplete
                  v-model="select"
                  :items="types"
                  item-text="BookTypeName"
                  item-value="id"
                  label="Vrsta"
                  return-object
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="2" xs="2" lg="2" xl="2" class="pr-2">
                <v-autocomplete
                  class="pr-3"
                  :items="docs"
                  v-model="selectDoc"
                  :label="lang.MainBookDocID"
                  item-text="DocID"
                  item-value="id"
                  return-object
                  persistent-hint
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="6" xs="2" lg="2" xl="2">
                <v-text-field
                  dense
                  label="Šifra odvjetnika (broj):"
                  v-model="selFreeF1"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="dispView">
              <v-col cols="12" sm="12" md="1" xs="1" lg="1" xl="1" class="pr-3" >
                <v-btn
                  text color="primary"
                  @click="analyze(1)">
                  {{lang.DoCount}}
                </v-btn>
              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="pr-3" >
                <v-btn
                  text color="primary"
                  @click="analyze(2)">
                  {{lang.Book}}
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6" md="2" xs="2" lg="2" xl="2" class="pr-3" >
                <v-btn
                  text color="primary"
                  @click="analyze(6)">
                  {{lang.Book4}}
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="pr-3" >
                <v-btn
                  text color="primary"
                  @click="analyze(5)">
                  {{lang.Book3}}
                </v-btn>
              </v-col>
              <v-col cols="12" sm="12" md="1" xs="1" lg="1" xl="1" class="pr-3" >
                <v-btn
                  text color="primary"
                  @click="analyze(3)">
                  {{lang.BookExp}}
                </v-btn>
              </v-col>
              <v-col cols="12" sm="12" md="1" xs="1" lg="1" xl="1" >
                <v-btn
                  text color="primary"
                  @click="analyze(4)">
                  {{lang.BookRec}}
                </v-btn>
              </v-col>
              <v-col cols="6" sm="6" md="6" xs="6" lg="2" xl="2" >
                <v-btn
                  text color="primary"
                  @click="card(1)">
                  Kartica URA
                </v-btn>
              </v-col>
              <v-col cols="6" sm="6" md="6" xs="6" lg="2" xl="2" >
                <v-btn
                  text color="primary"
                  @click="card(2)">
                  Kartica IRA
                </v-btn>
              </v-col>
              <v-col cols="6" sm="6" md="6" xs="6" lg="2" xl="2" >
                <v-btn
                  text color="primary"
                  @click="analyze(11)">
                  Po vrstama
                </v-btn>
              </v-col>
          </v-row>
          <v-row v-if="dispView">
              <v-col cols="12" sm="12" md="1" xs="1" lg="1" xl="1" class="pr-3" >

              </v-col>

              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="pr-3" >

              </v-col>
              <v-col cols="12" sm="6" md="2" xs="2" lg="2" xl="2" class="pr-3" >

              </v-col>
              <v-col cols="12" sm="6" md="1" xs="1" lg="1" xl="1" class="pr-3" >
                <v-switch
                  v-model="hideZeros"
                  :label="lang.hideZeros"
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="12" md="1" xs="1" lg="1" xl="1" class="pr-3" >

              </v-col>
              <v-col cols="12" sm="12" md="1" xs="1" lg="1" xl="1" >

              </v-col>
              <v-col cols="6" sm="6" md="6" xs="6" lg="2" xl="2" >
                <v-btn
                  text color="primary"
                  @click="openStatement(1)">
                  Lista ot.URE
                </v-btn>
              </v-col>
              <v-col cols="6" sm="6" md="6" xs="6" lg="2" xl="2" >
                <v-btn
                  text color="primary"
                  @click="openStatement(2)">
                  Lista ot.IRE
                </v-btn>
              </v-col>
          </v-row>

          <v-divider class="mt-1 mb-3"></v-divider>

          <div v-if="dispBook">
            <v-row class="pt-2">
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <MainBookList />
              </v-col>
            </v-row>
          </div>

          <v-row v-if="showCard1">
            <MainBookCard1 :items="items" />
          </v-row>
          <v-row v-if="showCard11">
            <MainBookCard3ByTypes :items="items" :types="types" />
          </v-row>

          <MainBookPrint ref="prnt" :kpi="kpi" />
          <div v-if="showCard3">
            <v-row class="pt-2">
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <MainLedgerBookIn :mainledgerAna="mainbookAna" :source="2" />
              </v-col>
            </v-row>
          </div>
          <div v-if="showCard4">
            <v-row class="pt-2">
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <MainLedgerBookOut :mainledgerAna="mainbookAna" :source="2" />
              </v-col>
            </v-row>
          </div>
          <div v-if="showCard6">
            <v-row class="pt-2">
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <MainBookTypesCRUD />
              </v-col>
            </v-row>
          </div>
          <div v-if="showCard7">
            <v-row class="pt-2">
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <MainBookDocsCRUD />
              </v-col>
            </v-row>
          </div>
          <div v-if="dispCard">
            <v-row class="pt-2">
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <MainBookCardInv :items="itemsInv" :type="cardType" :par="selectPa" :Tot1="Total1" :Tot2="Total2" :Tot3="Total3" :mainbookAna="mainbookAna" />
              </v-col>
            </v-row>
          </div>

        <v-row v-if="dispBank">
          <v-col cols="3" >
              <v-autocomplete
                v-bind:items="banks"
                v-model="selectBank"
                :label="lang.BankName"
                single-line
                item-text="BankName"
                item-value="id"
                return-object
                persistent-hint
              ></v-autocomplete>
            </v-col>
            <v-col cols="3" >
              <v-file-input v-model="somefile" single label="File input"></v-file-input>
            </v-col>
            <v-col cols="2" >
              <v-btn
                text color="primary"
                @click="onUpload">
                Upload
              </v-btn>
             </v-col>
       </v-row>

      <v-row v-if="showCard10">
        <MainBookCompCard :items="tItems" :head="head" :fTotal1="fTotal1" :fTotal2="fTotal2" :fTotal3="fTotal3" :fTotal4="fTotal4" :fTotal5="fTotal5" :fTotal6="fTotal6" :type="typeCard" :defCurrency="defCurrency" :hideLinks="hideLinks" />
      </v-row>
      <v-row v-if="dispType">
        <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
          <MainBookCompPSList :key="myDocumentSide" :type="typeCard" :defCurrency="defCurrency" />
        </v-col>
      </v-row>

  </v-container>
</template>

<script>
import dayjs from 'dayjs'
import MainBookList from './MainBookList'
import MainBookService from '@/services/MainBookService'
import MainBookCard1 from './MainBookCard1'
import MainBookCard3ByTypes from './MainBookCard3ByTypes'
import MainBookTypeService from '@/services/MainBookTypeService'
import MainBookDocService from '@/services/MainBookDocService'
import MainBookTypesCRUD from './MainBookTypesCRUD'
import MainBookDocsCRUD from './MainBookDocsCRUD'
import MainBookCompCard from '../MainBookComp/MainBookCompCard'
import PartnerService from '@/services/PartnerService'
import ledgerEN from './mainbookDescEn'
import ledgerHR from './mainbookDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import { v4 as uuidv4 } from 'uuid'
import {parseNum, round} from '@/snippets/allSnippets'
import MainLedgerBookIn from '../MainLedger/MainLedgerBookIn'
import MainLedgerBookOut from '../MainLedger/MainLedgerBookOut'
import MainBookCompPSList from '../MainBookComp/MainBookCompPSList'
import MainBookCardInv from './MainBookCardInv'
import MainBookPrint from './MainBookPrint'
import BankAccountService from '@/services/BankAccountService'
import MainLedgerService from '@/services/MainLedgerService'
import Api from '@/services/Api'
import { defMainBook, defNewMainBook } from '../MainLedger/mainledgerHelper'
import {dynamicSort,  dateFormat} from '@/snippets/allSnippets'
import { types } from 'pdfmake/build/pdfmake'

export default {
  name: 'mainbookheader',
  $_veeValidate: {
    validator: 'new'
  },
  data () {
    return {
      hideLinks: true,
      hideZeros: false,
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      dateFormatted: null,
      dateFormatted2: null,
      exRate: 1,
      showEUR: false,
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      date1: null,
      date2: null,
      msg: '',
      valid: true,
      startDate: null,
      endDate: null,
      mainbookAna: {
        CompanyId: 0,
        CompanyNameDatabase: '',
        BusinessYear: 0,
        UserId: 0,
        email: '',
        First: '',
        Last: '',
        fromDate: null,
        toDate: null,
      },
      comp: {},
      kpi: {},
      error: null,
      localError: false,
      size: 'sm',
      account4Loc: [],
      account4LocFrom: [],
      account4LocTo: [],
      partners: [],
      items: [],
      bsitems: [],
      printitems: [],
      expitems: [],
      docs: [],
      CompanyId: 0,
      selectDoc: {},
      selectPa: {},
      selectA4: {},
      selectA4from: {},
      selectA4to: {},
      selectUs3: {},
      mainQuery: {
        CompanyId: 1
      },
      havePath: '',
      PrintDate: null,
      lang: {},
      langC: {},
      saving: false,
      showCard1: false,
      showCard2: false,
      showCard3: false,
      showCard4: false,
      showCard5: false,
      showCard6: false,
      showCard7: false,
      bookType: [],
      bookTypeSelected: {
      },
      bookTypeSelectedName: 'TEM',
      sameAsFrom: false,
      RecCash: 0,
      RecBank: 0,
      RecFree: 0,
      RecVAT: 0,
      RecSpecial: 0,
      RecTotal: 0,
      ExpCash: 0,
      ExpBank: 0,
      ExpFree: 0,
      ExpVAT: 0,
      ExpSpecial: 0,
      ExpTotal: 0,
      Total1: 0,
      Total2: 0,
      Total3: 0,
      Total4: 0,
      Total5: 0,
      Total6: 0,
      Total7: 0,
      banks: [],
      selectBank: {},
      selectBankId: 0,
      somefile: null,
      MLFree2: null,
      MLFree3: null,
      MLFree4: null,
      MLFree5: null,
      mainbook: { },
      types: [],
      select: {},
      dispView: false,
      dispBank: false,
      dispBook: true,
      dispCard: false,
      pItems: [],
      itemsInv: [],
      payments: [],
      tItems: [],
      head: {},
      cardType: 0,
      fTotal1: 0,
      fTotal2: 0,
      fTotal3: 0,
      fTotal4: 0,
      fTotal5: 0,
      fTotal6: 0,
      showCard10: false,
      defCurrency: 'EUR',
      typeCard: 0,
      myDocumentSide: 1,
      typeSide: 1,
      partnerVATID: '',
      printType: 1,
      dispType: false,
      showStartType: 1,
      selFreeF1: null,
      showCard11: false
    }
  },
  components: {
    MainBookCard1,
    MainLedgerBookIn,
    MainLedgerBookOut,
    MainBookPrint,
    MainBookTypesCRUD,
    MainBookDocsCRUD,
    MainBookList,
    MainBookCardInv,
    MainBookCompCard,
    MainBookCompPSList,
    MainBookCard3ByTypes
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }
    this.defCurrency = this.$store.state.businessYear && this.$store.state.businessYear <= 2022 ? 'Kn' : 'EUR'
    this.exRate = this.showEUR ?  this.$store.state.defExRate : 1
    // need five arrays with partners, employes, tasktype, taskdepartment, users
    if (this.$store.state.companyid) {
      this.CompanyId = this.$store.state.companyid
      this.mainbookAna.CompanyId = this.$store.state.companyid
    }
    this.comp = this.$store.state.companyActiv
    if (this.CompanyId > 0) {
      this.partners = this.$store.state.partnerStore ? this.$store.state.partnerStore : []
    }
    if (this.$store.state.user.id) {
      this.mainbookAna.UserId = this.$store.state.user.id
    }
    if (this.$store.state.user.email) {
      this.mainbookAna.email = this.$store.state.user.email
    }
    if (this.$store.state.user.First) {
      this.mainbookAna.First = this.$store.state.user.First
    }
    if (this.$store.state.user.Last) {
      this.mainbookAna.Last = this.$store.state.user.Last
    }
    this.mainbookAna.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
    this.mainbookAna.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : null
    this.mainbookAna.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
    let locDate1 = new Date()
    this.PrintDate = dayjs().format('DD.MM.YYYY, H:mm')
    this.startDate = dayjs([this.mainbookAna.BusinessYear]).startOf('year').format('YYYY-MM-DD')
    this.endDate = dayjs([this.mainbookAna.BusinessYear]).endOf('year').format('YYYY-MM-DD')
    locDate1.getDate()
    locDate1.getMonth() + 1
    locDate1.getFullYear()
    this.banks = (await BankAccountService.choose(this.mainbookAna)).data.bankAccounts
    if (this.banks) {
      this.selectBank = this.banks ? this.banks.find(bank => (bank.DefaultAccount === true)) : {}
      this.selectBankId  = this.selectBank ? parseInt(this.selectBank.id) : null
      this.mainbookAna.selectBankId  = this.selectBank ? parseInt(this.selectBank.id) : null
    }
    const {data} = (await MainBookTypeService.index(this.mainbookAna))
    this.types = data.mainbooktypes && data.mainbooktypes.length > 0 ? data.mainbooktypes : []
    console.log('this.types', this.types)
    const haveDocs = (await MainBookDocService.index(this.mainbookAna)).data.mainbookdocs
    this.docs = haveDocs ? haveDocs : []

  },
  computed: {
    formIsValid () {
      return this.startDate !== null &&
        this.endDate !== null &&
        this.mainbookAna.CompanyId !== ''
    },
    formatDate1() {
      return this.startDate
        ? dateFormat(this.startDate)
        : "";
    },
    formatDate2() {
      return this.endDate
        ? dateFormat(this.endDate)
        : "";
    },
  },
  methods: {
    print(){
      this.printType = 1
      this.$refs.prnt.print()
    },
    allFalse ( ){
      this.showCard1 = false
      this.showCard2 = false
      this.showCard3 = false
      this.showCard4 = false
      this.showCard5 = false
      this.showCard6 = false
      this.showCard7 = false
      this.showCard7 = false
      this.showCard10 = false
      this.showCard11 = false
      this.dispBook = false
      this.showCard6 = false
      this.dispBank = false
      this.dispCard = false
      this.dispType = false
    },
    showStart(type) {
      this.allFalse()
      this.myDocumentSide +=1

      this.showStartType = type === 1 ? 1 : 2
      const doc = type === 1  ? 'BookInInvoices' : 'BookOutInvoices'
      const docLoc = type === 1  ? 'Početno stanje URE' : 'Početno stanje IRE'
      const bookTypeSelected = {}
      bookTypeSelected.text = type === 1  ? 'URE_PS' : 'IRE_PS'
      bookTypeSelected.value = type === 1  ? 101 : 102
      this.$store.dispatch('setDocumentActivHead', {})
      this.$store.dispatch('setDocumentActivItems', [])
      this.$store.dispatch('setActionType', 0)
      this.$store.dispatch('setDocumentActiv', doc)
      this.$store.dispatch('setDocumentActivLoc', docLoc)
      this.$store.dispatch('setDocumentSide', type)
      this.$store.dispatch('setbookTypeSelected', bookTypeSelected)
      const query = {}
      if (this.$store.state.companyid) {
        query.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        query.businessYear = this.$store.state.businessYear
      }
      if (this.$store.state.documentActiv) {
        query.documentName = this.$store.state.documentActiv
      }
      query.MLType = type
      query.searchName = null
      query.page =  0
      query.pageSize =  30
      query.searchItem =  null
      this.$store.dispatch('setOnPage', query)
      this.currentPath = '/crm/mainbookcompindex'
      this.currentDoc = doc

      this.dispType = true
    },
    async openStatement (type) {
      // try {
        this.allFalse()
        this.fTotal1 = 0
        this.fTotal2 = 0
        this.fTotal3 = 0
        this.fTotal4 = 0
        this.fTotal5 = 0
        this.fTotal6 = 0
        const newObj = {}
        this.mainledgerAna = {...newObj}

        this.mainledgerAna.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
        this.mainledgerAna.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : null
        this.mainledgerAna.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2022
        this.head = {}
        if (type === 1) {
          this.mainledgerAna.MLType = 1
          this.typeCard = 1
        }
        if (type === 2) {
          this.mainledgerAna.MLType = 2
          this.typeCard = 2
        }
        if (this.startDate) {
          this.mainledgerAna.docFromDate = dayjs(this.startDate).format('YYYY-MM-DD')
        }
        if (this.endDate) {
          this.mainledgerAna.docToDate = dayjs(this.endDate).format('YYYY-MM-DD')
        }
        this.head = {...this.mainledgerAna}
        this.pItems.splice(0,this.pItems.length)
        this.payments.splice(0,this.payments.length)
        this.tItems.splice(0,this.tItems.length)
        this.$store.dispatch('setSearchState', this.mainledgerAna)
        this.saving = true

        const find1 = {}
        find1.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
        find1.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2022
        find1.MLType = type === 1 ? 1 : 2


        console.log('1 find1', find1)
        const res1= await MainLedgerService.sumbyvat(find1)
        const allInv = res1.data && res1.data.documents ? res1.data.documents : []
        console.log('1 allInv', allInv.length)
        const find2 = {...find1}
        find2.notDue = true
        console.log('2 find2', find2)
        const res2 = await MainLedgerService.sumbyvat(find2)
        const notDueInv = res2.data && res2.data.documents ? res2.data.documents : []
        console.log('2 notDueInv', notDueInv.length)

        const find3 = {...find1}
        find3.openStatement = true
        console.log('3 this.find3', find3)
        const res3 = await MainLedgerService.sumbyvat(find3)
        const dueInv = res3.data && res3.data.documents ? res3.data.documents : []
        console.log('3 dueInv', dueInv.length)

        const res4 = await MainBookService.sumbyvat(this.mainledgerAna)
        const payments = res4.data && res4.data.documents ? res4.data.documents : []
        this.payments.length = 0

        if (payments) {
          payments.map( paym => {
            const newPay = {...paym}
            newPay.totalPayment = round((parseNum(paym.totalPayment1)  / this.exRate) ,2) + round((parseNum(paym.totalPayment2)  / this.exRate) ,2) + round((parseNum(paym.totalPayment3)  / this.exRate) ,2)
            this.payments.push(newPay)
          })
        }
        console.log('4 payments', this.payments.length)


         if (allInv) {
          allInv.map(pit => {
            if (pit.partnerVATID && pit.partnerVATID.trim().length > 0 ) {

              const nDueInv = pit.partnerVATID ? notDueInv.find(payit => payit.partnerVATID === pit.partnerVATID) : null
              const dueInvo = pit.partnerVATID ? dueInv.find(payit => payit.partnerVATID === pit.partnerVATID) : null
              const havePayment = pit.partnerVATID ? this.payments.find(payit => payit.partnerVATID === pit.partnerVATID) : null

              const allInv = pit.totalInvoice ? round((parseNum(pit.totalInvoice) / this.exRate) ,2) : 0
              const allDue = dueInvo && dueInvo.totalInvoice ? round((parseNum(dueInvo.totalInvoice) / this.exRate) ,2) : 0
              const allNotDue = nDueInv && nDueInv.totalInvoice ? round((parseNum(nDueInv.totalInvoice) / this.exRate) ,2) : 0
              const allPay = havePayment && havePayment.totalPayment ? havePayment.totalPayment : 0

              const havePartner = this.$store.state.partnerStore.find(partner => partner.partnerVATID === pit.partnerVATID)

              let saldo = 0

              if (type === 1) {
                saldo = round((allPay - allDue),2)
              }
              if (type === 2) {
                saldo = round((allDue - allPay),2)
              }

              if ((round((saldo + allNotDue),2) !== 0) && havePartner) {

                const newItem = {}
                newItem.partner = havePartner ? havePartner : {}
                newItem.partnerVATID = pit.partnerVATID
                newItem.allInv = allInv
                newItem.allDue = allDue
                newItem.allPay = allPay
                newItem.saldo = saldo
                newItem.allNotDue = allNotDue
                newItem.allOpen = round((saldo + allNotDue),2)
                newItem.saldo = saldo
                this.tItems.push(newItem)
                this.fTotal1 += allInv
                this.fTotal2 += allDue
                this.fTotal3 += allPay
                this.fTotal4 += saldo
                this.fTotal5 += allNotDue
                this.fTotal6 += round((saldo + allNotDue),2)
              }
           }
          })


          // add payments without partner invoices
          this.payments.map(havePayment => {
            const used = havePayment.partnerVATID ? allInv.find(p => p.partnerVATID === havePayment.partnerVATID) : false

            if (!used) {

              const allInv =  0
              const allDue =  0
              const allNotDue =  0
              const allPay = havePayment.totalPayment ? havePayment.totalPayment : 0

              let saldo = 0
              if (type === 1) {
                saldo = round((allPay - allInv),2)
              }
                if (type === 2) {
                saldo = round((allInv - allPay),2)
              }
              // let wNew = false
              // if (!havePayment.partnerVATID && havePayment.totalPayment !==0) {
              //   wNew = true
              // }
              // if (havePayment.partnerVATID && havePayment.partnerVATID.trim().length === 0 && havePayment.totalPayment !==0 && wNew === false) {
              //   wNew = true
              // }
              // if (havePayment.partnerVATID && havePayment.partnerVATID.trim().length > 0 && havePayment.totalPayment !==0 && wNew === false) {
              //   const haveInList = this.tItems.find(payit => payit.partnerVATID === havePayment.partnerVATID)
              //   if (typeof haveInList === 'undefined') {
                  const havePartner = this.$store.state.partnerStore.find(partner => partner.partnerVATID === havePayment.partnerVATID )

                  if (saldo !== 0 && havePartner && havePartner.partnerVATID && havePartner.partnerVATID.length > 0) {
                    const newItem = {}
                    newItem.partner = havePartner ? havePartner : {}
                    newItem.partnerVATID = havePayment.partnerVATID
                    newItem.allInv = allInv
                    newItem.allDue = allDue
                    newItem.allPay = allPay
                    newItem.saldo = saldo
                    newItem.allNotDue = allNotDue
                    newItem.allOpen = round((saldo + allNotDue),2)
                    this.tItems.push(newItem)
                    this.fTotal1 += 0
                    this.fTotal2 += 0
                    this.fTotal3 += allPay
                    this.fTotal4 += saldo
                    this.fTotal5 += 0
                    this.fTotal6 += round((saldo),2)
                //   }
                // }
              }
            }
            // if (wNew) {
            //   const newItem = {}
            //       newItem.partner = null
            //       newItem.partnerVATID = null
            //       newItem.allInv = allInv
            //       newItem.allDue = allDue
            //       newItem.allPay = allPay
            //       newItem.saldo = saldo
            //       newItem.allNotDue = allNotDue
            //       newItem.allOpen = round((saldo + allNotDue),2)
            //       this.tItems.push(newItem)
            //       this.fTotal1 += 0
            //       this.fTotal2 += 0
            //       this.fTotal3 += allPay
            //       this.fTotal4 += saldo
            //       this.fTotal5 += 0
            //       this.fTotal6 += round((saldo),2)
            // }
          })
          this.tItems.sort(dynamicSort("-allOpen"))
          this.showCard10 = true
         }

        // }
        // .catch(err => {
        //    console.log('Error ', err)
        //    this.error = 'Error  ' + err
        // })
        this.saving = false
      //}
      // catch (error) {
      //   this.error = 'Greska u konekciji na bazu' + error
      //   this.stateBooks = false
      // }
    },
    async card (type) {
      let haveErr = true
      if (this.selectPa && this.selectPa.partnerVATID) {
        this.allFalse()
        await this.doInitLoad(type)
        if (type === 1 || type === 2) {this.doTotalCard(type)}
        if (type === 3) {this.doTotalByTypes()}
        this.dispCard = true
        this.cardType = type
        haveErr = false
      }
      if (haveErr) {
        alert('ERROR! Nije određen partner! Partner is not selected!')
      }
    },
     async doInitLoad(type) {
      const newQr = {}
      this.mainbookAna = {...newQr}

      this.mainbookAna.partnerVATID = this.selectPa.partnerVATID ? this.selectPa.partnerVATID  : null
      this.mainbookAna.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
      this.mainbookAna.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2023
      this.mainbookAna.docFromDate = this.head && this.head.docFromDate ? dayjs(this.head.docFromDate).format('YYYY-MM-DD') : null
      this.mainbookAna.docToDate = this.head && this.head.docToDate ? dayjs(this.head.docToDate).format('YYYY-MM-DD') : null
      this.mainbookAna.MLType = type ? type : null
      const mbook = (await MainBookService.choose(this.mainbookAna)).data.mainbooks
      const mledgerAna = {}
      mledgerAna.partnerVATID = this.partnerVATID ? this.partnerVATID : null
      mledgerAna.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
      mledgerAna.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
      const mledger = (await MainLedgerService.books(this.mainbookAna)).data.documents
      this.pItems = []
      if (mbook && mbook.length > 0) {
        mbook.map(book => {
          let doIt = false
          const exB = round(parseNum(book.ExpBank),2)
          const exC = round(parseNum(book.ExpCash),2)
          const exF = round(parseNum(book.ExpFree),2)
          const reB = round(parseNum(book.RecBank),2)
          const reC = round(parseNum(book.RecCash),2)
          const reF = round(parseNum(book.RecFree),2)
          const exU = exB + exC + exF
          const reU = reB + reC + reF
          if (type === 1 && ( exU !== 0) ) {
            doIt = true
          }
          if (type === 2 && ( reU !== 0 )) {
            doIt = true
          }
          if (doIt){
            const object1 = {
              datum: book.BookDate,
              BookTypeName: 'Izvod',
              InvoiceNumber: book.bankStatementNumber ? book.bankStatementNumber : null,
              InvoiceDate: book.BookDate,
              InvoicePaymentDeadlineDate: book.InvoicePaymentDeadlineDate,
              debit: (type === 1 ? exU : reU ),
              credit: 0,
              desc1: book.BookDesc1,
              desc2: book.BookDesc2,
              docID: (book.docID && book.docID !==0 ? parseInt(book.BookID) : book.id) ,
              datum2: dayjs(book.BookDate).format('YYYY-MM-DD'),
              type: 'payment'
            }
            this.pItems.push(object1)
          }
        })
      }
      if (mledger && mledger.length > 0) {
        mledger.map(book => {
          let runIt = true
          const object1 = {}
          if (runIt) {
            object1.datum = book.BookingDate,
            object1.BookTypeName = 'Račun',
            object1.InvoiceNumber = book.InvoiceNumber,
            object1.InvoiceDate = book.InvoiceDate,
            object1.InvoicePaymentDeadlineDate = book.InvoicePaymentDeadlineDate,
            object1.debit = 0,
            object1.credit = book.InvoiceAmount,
            object1.desc1 = book.MLFree5,
            object1.desc2 = "",
            object1.docID = parseInt(book.BookID),
            object1.type = 'invoice',
            object1.datum2 = dayjs(book.BookingDate).format('YYYY-MM-DD')
          }
          if(Object.keys(object1).length) {
            this.pItems.push(object1)
          }
        })
      }
    },
    doTotalCard(type) {
      this.itemsInv.length = 0
      this.pItems.sort(dynamicSort("datum2"))

      this.Total1 = 0
      this.Total2 = 0
      this.Total3 = 0
      this.Total4 = 0
      let itLine = 0
      if (this.pItems) {
        this.pItems.map(item => {
          itLine += 1
          if (type === 1) {
            this.Total1 += parseFloat(item.debit)
            this.Total2 += parseFloat(item.credit)
          }
          if (type === 2) {
            this.Total1 += parseFloat(item.credit)
            this.Total2 += parseFloat(item.debit)

          }
          //this.Total3 += parseFloat(item.DebitForegin)
          //this.Total4 += parseFloat(item.CreditForegin)
          const object2 = {}

          object2.itBookName = itLine + '. '
          if (type === 1) {
            object2.debit = item.debit
            object2.credit = item.credit
          }
          if (type === 2) {
            object2.debit = item.credit
            object2.credit = item.debit
          }
          object2.CreditDebit= this.Total1 - this.Total2
          object2.idSel = itLine

          const object3 = {...item, ...object2}
          this.itemsInv.push(object3)
        })
      }
      this.Total3 = this.Total1 - this.Total2
    },
    mainbooktypes() {
      this.dispBook = false
      this.showCard7 = false
      this.dispView = false
      this.dispBank = false
      this.showCard6 = !this.showCard6
    },
    mainbookdocs() {
      this.showCard7 = !this.showCard7
      this.dispBook = false
      this.showCard6 = false
      this.dispView = false
      this.dispBank = false
    },
    showBook() {
      this.dispBook = !this.dispBook
      this.showCard6 = false
      this.showCard7 = false
      this.showCard10 = false
      this.dispView = false
      this.dispBank = false
    },
    showView() {
      this.dispBook = false
      this.showCard6 = false
      this.showCard7 = false
      this.showCard10 = false
      this.dispBank = false
      this.dispView = !this.dispView
    },
    showBank() {
      this.dispBook = false
      this.showCard6 = false
      this.showCard7 = false
      this.showCard10 = false
      this.dispView = false
      this.dispBank = !this.dispBank
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    localDate(dateToFormat) {
       return dateFormat(dateToFormat)
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    getList  (item, queryText, itemText) {
      return itemText.toLocaleLowerCase().startsWith(queryText.toLocaleLowerCase())
    },
    printOld () {
      // opens the "print dialog" of your browser to print the element
      // var bdhtml = window.document.body.innerHTML
      // window.document.body.innerHTML = document.getElementById('printArea').innerHTML
      // window.print()
      // window.document.body.innerHTML = bdhtml
      this.$htmlToPaper('printArea')
    },
    async goto (type) {
      try {
        this.$store.dispatch('setTaskTypeid', type)
        this.$store.dispatch('seteditItem', 0)
        await this.$router.push({
          name: 'mainbookcreate'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async analyze (type) {
      try {

        // do analysis from database
        this.error = null
        this.localError = false
        this.showCard1 = false
        this.showCard2 = false
        this.showCard3 = false
        this.showCard4 = false
        this.showCard5 = false
        this.showCard6 = false
        this.showCard7 = false
        this.showCard11 = false
        // type 1 = balance sheet, 2 = partner card,, 3 = partner open, 4 = analysis, 5= all partners open
        // this.mainbookAna.type = type
        if (this.selectPa.id) {
          this.mainbookAna.partnerId = this.selectPa.id
        }
        if (this.select.id) {
          this.mainbookAna.BookTypeId = this.select.id
        }
        if (this.selectDoc && this.selectDoc.id) {
          this.mainbookAna.BookDocId = this.selectDoc.id
        }
        this.mainbookAna.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
        this.mainbookAna.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
        this.mainbookAna.docFromDate = this.startDate ? dayjs(this.startDate).format('YYYY-MM-DD') : null
        this.mainbookAna.docToDate = this.endDate ? dayjs(this.endDate).format('YYYY-MM-DD') : null
        this.mainbookAna.fromDate = this.startDate ? dayjs(this.startDate).format('YYYY-MM-DD') : null
        this.mainbookAna.toDate = this.endDate ? dayjs(this.endDate).format('YYYY-MM-DD') : null
        this.mainbookAna.FreeF1 = this.selFreeF1 ? this.selFreeF1: null
        console.log('Trazim type', type)
        if (type === 5) {
            this.mainbookAna.BookTypeId = 180
        }
        if (type === 1 || type === 2 || type === 5 || type === 6 || type === 11) {
          await MainBookService.choose(this.mainbookAna)
          .then(res => {

            this.items = res.data.mainbooks ? (this.mainbookAna.FreeF1 ? res.data.mainbooks[0] : res.data.mainbooks) : []
            console.log('this.items', this.items)

            if (this.items) {
              if ( type === 6) {
                // filter only book
                const newItems = this.items.filter(item => item.BookDocId === null || item.BookDocId === 0)
                this.items = JSON.parse(JSON.stringify(newItems))
              }

              this.showCard1 = type === 1 ? true : false
              this.showCard2 = type === 2 ? true : false
              if (type === 2 || type === 5 || type === 6)
              {
                const newType = type === 6 ? type = 2 : type
                this.printKpi(newType, this.hideZeros)
              }
              this.showCard11 = type === 11 ? true : false
            }
          })
          .catch(err => {
            this.error = 'Error  ' + err
          })
        }
        if (type === 3 ) {
          this.showCard3 = !this.showCard3
        }
        if (type === 4 ) {
          this.showCard4 = !this.showCard4
        }

      } catch (error) {
        // eslint-disable-next-line
        console.log(' Error 2?? ', error)
        this.error = 'Greska u konekciji na bazu'
      }
    },
    async printKpi(type, zeros){
      await this.mountKpi()
      await this.initKpi(type)
      if (type === 5 ) {
        this.kpi.RacuniIspis.sort((a,b) => (a.BookDocDesc > b.BookDocDesc) ? 1 : ((b.BookDocDesc > a.BookDocDesc) ? -1 : 0))
      }
      await this.$refs.prnt.print(type, zeros)
    },
    mountKpi() {
        this.RecCash = 0
        this.RecBank = 0
        this.RecFree = 0
        this.RecVAT = 0
        this.RecSpecial = 0
        this.RecTotal = 0

        this.ExpCash = 0
        this.ExpBank = 0
        this.ExpFree = 0
        this.ExpVAT = 0
        this.ExpSpecial = 0
        this.ExpTotal = 0
        this.Total1 = 0
        this.Total2 = 0
        this.Total3 = 0
        this.Total4 = 0
        this.Total5 = 0
        this.Total6 = 0
        this.Total7 = 0

        if (this.items) {
          this.items.map(item => {
            this.RecCash += parseNum(item.RecCash)
            this.RecBank += parseNum(item.RecBank)
            this.RecFree += parseNum(item.RecFree)
            this.RecVAT += parseNum(item.RecVAT)
            this.RecSpecial += parseNum(item.RecSpecial)
            this.RecTotal += parseNum(item.RecTotal)

            this.ExpCash += parseNum(item.ExpCash)
            this.ExpBank += parseNum(item.ExpBank)
            this.ExpFree += parseNum(item.ExpFree)
            this.ExpVAT += parseNum(item.ExpVAT)
            this.ExpSpecial += parseNum(item.ExpSpecial)
            this.ExpTotal += parseNum(item.ExpTotal)
          })
        }
        this.Total1 = this.RecCash - this.ExpCash
        this.Total2 = this.RecBank - this.ExpBank
        this.Total3 = this.RecFree - this.ExpFree
        this.Total4 = this.RecVAT - this.ExpVAT
        this.Total5 = this.ExpSpecial
        this.Total6 = this.RecTotal - this.ExpTotal
        this.Total7 = this.RecSpecial
    },
    initKpi(type) {
      this.kpi = {}
      this.kpi.Identifikator = uuidv4()
      this.kpi.OIB = this.$store.state.companyActiv.CompanyVATID ? this.$store.state.companyActiv.CompanyVATID : ''
      this.kpi.Naziv = this.$store.state.companyActiv.CompanyName ? this.$store.state.companyActiv.CompanyName : ''
      this.kpi.Mjesto = (this.$store.state.companyActiv.CompanyPostcode ? this.$store.state.companyActiv.CompanyPostcode : '') + ' ' +this.$store.state.companyActiv.CompanyCity ? this.$store.state.companyActiv.CompanyCity : ''
      this.kpi.Ulica = this.$store.state.companyActiv.CompanyStreet ? this.$store.state.companyActiv.CompanyStreet : ''
      this.kpi.Broj = this.$store.state.companyActiv.CompanyBuildingNameNumber ? this.$store.state.companyActiv.CompanyBuildingNameNumber : ''
      this.kpi.DodatakKucnomBroju = ''
      this.kpi.DatumOd = this.startDate ? this.startDate : null
      this.kpi.DatumDo = this.endDate ? this.endDate : null
      this.kpi.PodrucjeDjelatnosti = this.$store.state.companyActiv.CompanyInvoiceType ? this.$store.state.companyActiv.CompanyInvoiceType : ''
      this.kpi.SifraDjelatnosti = this.$store.state.companyActiv.CompanyInvoiceHint ? this.$store.state.companyActiv.CompanyInvoiceHint : ''
      this.kpi.Ime = this.$store.state.user.First ? this.$store.state.user.First : ''
      this.kpi.Prezime = this.$store.state.user.Last ? this.$store.state.user.Last : ''
      this.kpi.Ukupno = {}
      this.kpi.Ukupno.RecCash = this.RecCash ? round(this.RecCash, 2)  : 0
      this.kpi.Ukupno.RecBank = this.RecBank ? round(this.RecBank, 2)  : 0
      this.kpi.Ukupno.RecFree = this.RecFree ? round(this.RecFree, 2)  : 0
      this.kpi.Ukupno.RecVAT = this.RecVAT ? round(this.RecVAT, 2)  : 0
      this.kpi.Ukupno.RecSpecial = this.RecSpecial ? round(this.RecSpecial, 2)  : 0
      this.kpi.Ukupno.RecTotal = this.RecTotal ? round(this.RecTotal, 2)  : 0

      this.kpi.Ukupno.ExpCash = this.ExpCash ? round(this.ExpCash, 2)  : 0
      this.kpi.Ukupno.ExpBank = this.ExpBank ? round(this.ExpBank, 2)  : 0
      this.kpi.Ukupno.ExpFree = this.ExpFree ? round(this.ExpFree, 2)  : 0
      this.kpi.Ukupno.ExpVAT = this.ExpVAT ? round(this.ExpVAT, 2)  : 0
      this.kpi.Ukupno.ExpSpecial = this.ExpSpecial ? round(this.ExpSpecial, 2)  : 0
      this.kpi.Ukupno.ExpTotal = this.ExpTotal ? round(this.ExpTotal, 2)  : 0

      this.kpi.Ukupno.Total1 = this.Total1 ? round(this.Total1, 2)  : 0
      this.kpi.Ukupno.Total2 = this.Total2 ? round(this.Total2, 2)  : 0
      this.kpi.Ukupno.Total3 = this.Total3 ? round(this.Total3, 2)  : 0
      this.kpi.Ukupno.Total4 = this.Total4 ? round(this.Total4, 2)  : 0
      this.kpi.Ukupno.Total5 = this.Total5 ? round(this.Total5, 2)  : 0
      this.kpi.Ukupno.Total6 = this.Total6 ? round(this.Total6, 2)  : 0
      this.kpi.Ukupno.Total7 = this.Total7 ? round(this.Total7, 2)  : 0
      this.kpi.DocId = this.selectDoc && this.selectDoc.DocID ? this.selectDoc.DocID : null
      this.kpi.DocDesc = this.selectDoc && this.selectDoc.DocName ? this.selectDoc.DocName : null
      this.kpi.DocOpenDate = this.selectDoc && this.selectDoc.DocOpenDate ? this.selectDoc.DocOpenDate : null
      this.kpi.DocClosecDate = this.selectDoc && this.selectDoc.DocClosecDate ? this.selectDoc.DocClosecDate : null
      this.kpi.RacuniIspis = this.items ? this.items.slice() : []
      this.kpi.Type = type
      this.kpi.Docs = this.docs
    },
    close () {
      this.show = false
    },
    onUpload() {
        this.MLFree2 = ''
        this.MLFree3 = ''
        this.MLFree4 = ''
        this.MLFree5 = ''
        const newO = {}
        this.mainbookAna = {...newO}
        if (this.somefile) {
          let fd = new FormData()
          fd.append('companyId', this.$store.state.companyid)
          fd.append('originalName', this.somefile.name)
          fd.append('typeDoc', 'BS')
          fd.append('myFile', this.somefile, this.somefile.name)
          this.somefile = null

          Api().post('bookin/invoice', fd).then(response => {
              this.data = response.data
              if (this.data) {
                this.mainbookAna.MLFree2 = this.data.originalname ? this.data.originalname : ''
                this.mainbookAna.MLFree3 = this.data.filename ? this.data.filename : ''
                this.mainbookAna.MLFree4 = this.data.destination ? this.data.destination : ''
                this.mainbookAna.MLFree5 = this.data.path ? this.data.path : ''
                this.mainbookAna.bankAccountID = this.selectBankId ? this.selectBankId : ''
                this.readbsml()
              }
          }).catch(error => {
              alert(this.lang.alertError1)
              if (error.response.status === 422) {
                  this.errors = error.response.data.errors || {};
              }
          });
        } else {
          this.text = this.lang.BSfileError
          this.color = 'red'
          this.snackbar = true
          this.timeout = 4000
        }
    },
    async readbsml() {
      try {

        await MainLedgerService.readbsml(this.mainbookAna).then(async(res) => {

          this.ml = res.data.allData

          //this.mainledger.bankAccountID = this.mainbookAna.selectBankId
          this.bsitems = []
          //let nr = 0
          if (this.ml.nine5) {
            this.ml.nine5.map(nine5 => {
              this.mainbook = defMainBook()
              this.mainbook = {...this.mainbook, ...defNewMainBook()}
              //nr += 1
              this.mainbook.BookDate = this.ml.nine7[0].DatumIzvatka6
              this.mainbook.BookDesc1 = 'Izvod br: ' + this.ml.nine7[0].RedniBrojIzvatka4 + ',' + dayjs(this.ml.nine7[0].DatumIzvatka6).format('DD.MM.YYYY') + ',' + (nine5.ValutaPokrica ? nine5.ValutaPokrica : '')
              this.mainbook.partnerName  = nine5.NazivPrimatelja3 ? nine5.NazivPrimatelja3 : ''
              this.mainbook.partnerBankIBAN  = nine5.RacunPrimatelja2 ? nine5.RacunPrimatelja2 : ''
              // this.mainbook.bankStatmentNumberDetail = nr
              // this.mainbook.bankReference1  = nine5.PozivNaBrojPlatitelja14.substr(0, 4) ? nine5.PozivNaBrojPlatitelja14.substr(0, 4) : ''
              // this.mainbook.bankReference2  = nine5.PozivNaBrojPlatitelja14 && nine5.PozivNaBrojPlatitelja14.length > 4 ? nine5.PozivNaBrojPlatitelja14.substr(4, nine5.PozivNaBrojPlatitelja14.length) : ''
              // this.mainbook.bankCall1  = nine5.PozivNaBrojPrimatelja15.substr(0, 4) ? nine5.PozivNaBrojPrimatelja15.substr(0, 4) : ''
              // this.mainbook.bankCall2  = nine5.PozivNaBrojPlatitelja15 && nine5.PozivNaBrojPlatitelja15.length > 4 ? nine5.PozivNaBrojPlatitelja15.substr(4, nine5.PozivNaBrojPlatitelja15.length) : ''
              // 10 isplata  20 uplata
              this.mainbook.ExpBank  = nine5.OznakaTransakcije1 === "10" &&  nine5.Iznos13 ? nine5.Iznos13 : 0
              this.mainbook.ExpTotal  = nine5.OznakaTransakcije1 === "10" &&  nine5.Iznos13 ? nine5.Iznos13 : 0
              this.mainbook.RecBank  = nine5.OznakaTransakcije1 === "20" &&  nine5.Iznos13 ? nine5.Iznos13 : 0
              this.mainbook.RecTotal  = nine5.OznakaTransakcije1 === "20" &&  nine5.Iznos13 ? nine5.Iznos13 : 0
              this.mainbook.ExpBank  = nine5.OznakaTransakcije1 === "10" &&  nine5.Iznos13 ? nine5.Iznos13 : 0
              this.mainbook.BookRecExp  = nine5.OznakaTransakcije1 === "20" ? 1 : 2
              if (this.mainbook.partnerBankIBAN){
                const cIban = {
                  partnerBankIBAN: this.mainbook.partnerBankIBAN
                }
                PartnerService.checkIBAN(cIban)
                .then(res => {
                  if (res.data.partner) {
                    const pr = res.data.partner
                    this.mainbook.partnerId = pr.id ? pr.id : null
                    this.mainbook.partnerName = pr.partnerName ? pr.partnerName : null
                    this.mainbook.partnerNameShort = pr.partnerNameShort ? pr.partnerNameShort : null
                    this.mainbook.partnerNameDatabase = pr.partnerNameDatabase ? pr.partnerNameDatabase : null
                    this.mainbook.partnerVATID = pr.partnerVATID ? pr.partnerVATID : null

                  }
                })
              }
              this.bsitems.push(this.mainbook)
            })
          }
          if (this.bsitems.length > 0) {
            await MainBookService.saveitems(this.bsitems)
            this.$store.dispatch('setResetList', this.$store.state.resetList + 1)
          }

        }).catch(error => {
          // eslint-disable-next-line
          console.log('Error ', error)
        })
      } catch (error) {
        this.locError = this.lang.alertError4
        this.text = 'Error ' + error
        this.color = 'red'
        this.snackbar = true
        alert(this.locError)
      }
    },




  },
  watch: {
    'selectPa' () {
      if (!this.selectPa) {
        this.allFalse()
      }
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
